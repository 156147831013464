import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { getFixedGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../../../client-config";
import { font } from "../../styled";

const Figure = ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const fixedProps = getFixedGatsbyImage(
    node.asset._id,
    { width: 200 },
    clientConfig.sanity
  );
  return (
    <Fig>
      <Img fixed={fixedProps} alt={node.alt} />
      {node.caption && <FigCaption>{node.caption}</FigCaption>}
    </Fig>
  );
};

const Fig = styled.figure`
  margin: 0 auto;
  width: 200px;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
`;

const FigCaption = styled.figcaption`
  font-size: ${font.smallSize};
`;

export default Figure;
