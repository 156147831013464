import { isFuture } from "date-fns";
import getSlug from "speakingurl";

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}
export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(new Date(publishedAt));
}

export function getBlogUrl(slug) {
  return `/books/${slug.current || slug}/`;
}

export function getRecipeUrl(slug) {
  return `/recipes/${slug.current || slug}/`;
}

export function getReadingChallengeUrl(slug) {
  return `/reading-challenges/${slug.current || slug}/`;
}

export function resolveUrl(type, slug) {
  switch (type) {
    case "recipe":
      return getRecipeUrl(slug);

    case "post":
      return getBlogUrl(slug);

    case "readingChallenge":
      return getReadingChallengeUrl(slug);

    default:
      return "/";
  }
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

export const kebabCase = (value) =>
  value ? getSlug(value, { truncate: 200, symbols: true }) : "";
