const colour = {
  black: "#202123",
  darkGray: "#32373e",
  gray: "#697a90",
  lightGray: "#b4bcc7",
  veryLightGray: "#e7ebed",
  white: "#fff",
};

export default colour;
