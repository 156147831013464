const slideShowAltHelper = (slide) => {
  if (slide.reference) {
    return slide.reference.title;
  }
  if (slide.link) {
    return slide.link;
  }
  return "Home";
};

export default slideShowAltHelper;
