import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { buildImageObj, imageUrlFor } from "../../../helpers";
import { colour, font, media } from "../../styled";
import SlideShowCaption from "./SlideShowCaption";
import PortableText from "../PortableText";
import slideShowLinkHelper from "./helpers/slideShowLinkHelper";
import slideShowAltHelper from "./helpers/slideShowAltHelper";

const SlideShow = ({ slides, showNav = true }) => {
  const [index, setIndex] = useState(0);
  const len = slides.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % len);
    }, 4000);

    return () => clearInterval(interval);
  });

  if (!slides) return null;

  function handlePrev() {
    setIndex(Math.max(index - 1, 0));
  }

  function handleNext() {
    setIndex(Math.min(index + 1, len - 1));
  }

  return (
    <StyledSlideShow>
      <SlidesContainer>
        <Carousel
          data-index={index}
          style={{ transform: `translate3d(${index * -100}%, 0, 0)` }}
        >
          {slides.map((slide) => (
            <Slide key={slide._key}>
              <Link
                to={slideShowLinkHelper(slide)}
                aria-label={slideShowAltHelper(slide)}
              >
                {slide.asset && (
                  <img
                    role="presentation"
                    src={imageUrlFor(buildImageObj(slide))
                      .width(1200)
                      .height(Math.floor((9 / 16) * 1200))
                      .fit("crop")
                      .url()}
                    loading="eager"
                    width={1200}
                    height={Math.floor((9 / 16) * 1200)}
                    alt={slideShowAltHelper(slide)}
                  />
                )}
              </Link>
            </Slide>
          ))}
        </Carousel>
        <SlideShowCaption slide={slides[index]} />
        {slides[index]._rawCredit && (
          <StyledCredit>
            <PortableText blocks={slides[index]._rawCredit} />
          </StyledCredit>
        )}
      </SlidesContainer>
      {showNav && (
        <SlideShowNav>
          <button onClick={handlePrev} disabled={index === 0} type="button">
            Prev
          </button>
          <span>{`${index + 1} of ${len}`}</span>
          <button
            onClick={handleNext}
            disabled={index === len - 1}
            type="button"
          >
            Next
          </button>
        </SlideShowNav>
      )}
    </StyledSlideShow>
  );
};

const StyledSlideShow = styled.div`
  color: ${colour.white};
  overflow: hidden;
  margin: 0 -2rem;
  border-radius: 8px;
`;

const SlideShowNav = styled.div`
  padding: 0.5rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;

  button {
    outline: none;
    -webkit-font-smoothing: inherit;
    appearance: none;
    font: inherit;
    background: none;
    border: none;
    color: inherit;
    margin: 0;
    padding: 0;
  }
`;

const Carousel = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transition: transform 800ms;
`;

const Slide = styled.li`
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
  }
`;

const SlidesContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledCredit = styled.div`
  p {
    text-align: right;
    font-size: ${font.smallSize};
    line-height: ${font.xSmallLineHeight};
    color: ${colour.darkGray};
    margin: 0;
    ${media.maxSmall} {
      display: none;
    }
  }
`;

export default SlideShow;
