import React from "react";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import serializers from "./Serializers";

const PortableText = ({ blocks }) => (
  <BasePortableText
    blocks={blocks}
    serializers={serializers}
    projectId={clientConfig.sanity.projectId}
    dataset={clientConfig.sanity.dataset}
  />
);

export default PortableText;
