import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import Icon from "../Icon";
import { media, colour, font } from "../../styled";
import routes from "../../../constants/routes";
import { rssFeedUrl } from "../../../constants";

const Header = ({
  siteTitle,
  onHideNav,
  onShowNav,
  showNav,
  social,
  brand,
}) => (
  <Wrapper>
    <Branding>
      <Link to={routes.home} aria-label={siteTitle}>
        <Img fluid={brand.asset.fluid} />
      </Link>
    </Branding>

    <ToggleNavButton
      aria-label="Main menu"
      role="button"
      onClick={showNav ? onHideNav : onShowNav}
    >
      <Icon symbol="hamburger" />
    </ToggleNavButton>

    <Nav showNav={showNav}>
      <ul role="menu">
        <li role="menuitem">
          <Link to={routes.home} aria-label="Home">
            <Icon symbol="home" size={18} />
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.books} aria-label="books">
            BOOK Reviews
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.authors} aria-label="authors">
            Authors
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.tags} aria-label="all about books">
            All About Books
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.readingChallenges} aria-label="reading challenges">
            READING challenges
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.about} aria-label="about">
            ABOUT
          </Link>
        </li>

        <MinSocialLinks>
          <ul role="menu">
            <li role="menuitem">
              <a href={social.instagram} aria-label="instagram">
                <Icon symbol="instagram" />
              </a>
            </li>
            <li role="menuitem">
              <a href={social.twitter} aria-label="twitter">
                <Icon symbol="twitter" />
              </a>
            </li>
            <li role="menuitem">
              <a href={`mailto:${social.email}`} aria-label="email">
                <Icon symbol="email" />
              </a>
            </li>
            <li role="menuitem">
              <a href={rssFeedUrl} aria-label="rss">
                <Icon symbol="rss" size={18} />
              </a>
            </li>
          </ul>
        </MinSocialLinks>
      </ul>
    </Nav>
    <SocialLinks>
      <ul role="menu">
        <li role="menuitem">
          <a href={social.instagram} aria-label="instagram">
            <Icon symbol="instagram" />
          </a>
        </li>
        <li role="menuitem">
          <a href={social.twitter} aria-label="twitter">
            <Icon symbol="twitter" />
          </a>
        </li>
        <li role="menuitem">
          <a href={`mailto:${social.email}`} aria-label="email">
            <Icon symbol="email" />
          </a>
        </li>
        <li role="menuitem">
          <a href={rssFeedUrl} aria-label="rss">
            <Icon symbol="rss" size={18} />
          </a>
        </li>
      </ul>
    </SocialLinks>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  z-index: 100;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 960px;
  padding: 1em 0 0 0.5em;
  display: flex;

  ${media.minSmall} {
    flex-direction: column;
    padding: 1.5em 1.5em 0 1.5em;
  }
`;

const Branding = styled.h1`
  font-size: inherit;
  margin: 0;
  flex: 1;
  border-radius: 6px;
  overflow: hidden;
  img a {
    display: inline-block;
    padding: 0.5em;
    color: inherit;
    text-decoration: none;

    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.accentColor};
      }
    }
  }
`;

const ToggleNavButton = styled.button`
  appearance: none;
  font-size: 25px;
  border: none;
  background: none;
  margin: 0;
  padding: 0.5rem;
  outline: none;

  & svg {
    display: block;
  }

  ${media.minSmall} {
    display: none;
  }
`;

const Nav = styled.nav`
  display: none;
  font-size: ${font.smallSize};
  line-height: ${font.smallLineHeight};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  ul {
    margin: 0;
    padding: 0;
  }

  ul li a {
    display: block;
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
  }

  @media (hover: hover) {
    ul li a:hover {
      color: ${(props) => {
        return props.theme.accentColor;
      }};
    }
  }

  ${media.maxSmall} {
    position: absolute;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    left: 0;
    right: 0;
    top: 1rem;

    ul {
      padding: 1rem 0;
    }

    ul li a {
      padding: 0.5rem 1.5rem;
    }
  }

  ${media.minSmall} {
    display: block;

    ul {
      list-style: none;
      display: flex;
      justify-content: center;
    }

    ul li a {
      padding: 0.5rem;
    }
  }
  ${(props) =>
    props.showNav &&
    css`
      display: block;
    `}
`;

const SocialLinks = styled.div`
  color: ${colour.black};
  ${media.maxSmall} {
    display: none;
  }
  ${media.minSmall} {
    display: block;
    flex-direction: row;
    margin: auto;
    justify-content: center;

    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      color: ${(props) => props.theme.primaryColor};
      padding: 0;
    }

    ul li a {
      padding: 0.5rem;
      color: ${(props) => props.theme.primaryColor};
    }
    @media (hover: hover) {
      ul li a:hover {
        color: ${(props) => props.theme.accentColor};
      }
    }
  }
`;

const MinSocialLinks = styled.li`
  display: none;

  ${media.maxSmall} {
    display: flex;
    flex-direction: row;

    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    ul li a {
      padding: 0 0.5rem;
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;

export default Header;
