import React from "react";
import styled from "styled-components";
import Header from "../Header";
import { GlobalStyles, media } from "../../styled";
import Footer from "../Footer";

const Layout = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  brand,
  brandWidth,
  brandHeight,
  social,
  colours,
}) => (
  <GlobalStyles colours={colours}>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      brand={brand}
      brandWidth={brandWidth}
      brandHeight={brandHeight}
      social={social}
    />
    <Content>{children}</Content>
    <Footer social={social} />
  </GlobalStyles>
);

const Content = styled.div`
  min-height: calc(100% - 73px - 152px);

  ${media.minSmall} {
    min-height: calc(100% - 90px - 169px);
  }
`;

export default Layout;
