import React from "react";
import styled from "styled-components";
import { media } from "../../styled";

const Container = ({ children, fullWidth = false }) => {
  return <StyledContainer fullWidth={fullWidth}>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  max-width: ${(props) => (props.fullWidth ? "100%" : "960px")};
  padding: 0 1.5em;
  margin: 1rem auto;

  ${media.minSmall} {
    padding: 0 2em;
  }
`;

export default Container;
