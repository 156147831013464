import imageUrlBuilder from "@sanity/image-url";
import clientConfig from "../../client-config";

const builder = imageUrlBuilder(clientConfig.sanity);

function imageUrlFor(source) {
  return builder.image(source);
}

export default imageUrlFor;
