import { resolveUrl } from "../../../../helpers";

const slideShowLinkHelper = (slide) => {
  if (slide.reference) {
    return resolveUrl(slide.reference._type, slide.reference.slug);
  }
  if (slide.link) {
    return `/${slide.link}`;
  }
  return "/";
};

export default slideShowLinkHelper;
