import CookieConsent from "react-cookie-consent";
import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";

import { Layout } from "../components/elements";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      brand {
        asset {
          fluid(maxWidth: 683, maxHeight: 137) {
            ...GatsbySanityImageFluid
          }
        }
      }
      brandWidth
      brandHeight
      primaryColor {
        hex
      }
      accentColor {
        hex
      }
      backgroundColor {
        hex
      }
      instagram
      email
      twitter
    }
  }
`;

const LayoutContainer = ({ children }) => {
  const [showNav, setShowNav] = useState(false);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        const social = {
          instagram: data.site.instagram,
          email: data.site.email,
          twitter: data.site.twitter,
        };

        const colours = {
          primary: data.site.primaryColor.hex,
          accent: data.site.accentColor.hex,
          background: data.site.backgroundColor.hex,
        };

        return (
          <Layout
            showNav={showNav}
            siteTitle={data.site.title}
            brand={data.site.brand}
            brandWidth={data.site.brandWidth}
            brandHeight={data.site.brandHeight}
            social={social}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            colours={colours}
          >
            {children}
            <CookieConsent
              enableDeclineButton
              cookieName="coffeeBooksAndCake"
              buttonStyle={{
                color: "white",
                fontWeight: "bolder",
                backgroundColor: "green",
                borderRadius: "4px",
              }}
              declineButtonStyle={{
                color: "black",
                backgroundColor: "lightgray",
                borderRadius: "4px",
              }}
              flipButtons
              buttonClasses="btn btn-primary"
              containerClasses="alert alert-warning col-lg-12"
            >
              We use cookies. We&apos;ve recently updated our privacy policy to
              give you more detail about how we use cookies.
              <a href="/pages/privacy-policy" style={{ color: "#ADD8E6" }}>
                Read our privacy policy here.
              </a>
            </CookieConsent>
          </Layout>
        );
      }}
    />
  );
};

export default LayoutContainer;
