import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../styled";
import { kebabCase } from "../../../helpers";

const SubMenu = ({ items, type }) => (
  <Wrapper>
    <Nav>
      <ul role="menu">
        {items.map((g) => (
          <li key={g.fieldValue} role="menuitem">
            <Link to={`/${type}/${kebabCase(g.fieldValue)}`}>
              {g.fieldValue}
            </Link>
          </li>
        ))}
      </ul>
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 960px;
  padding: 1em 0 0 0;
  display: flex;
  text-align: center;

  ${media.minSmall} {
    padding: 0;
  }
`;

const Nav = styled.nav`
  display: flex;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  ul li a {
    display: block;
    color: inherit;
    text-decoration: none;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
    padding: 0.5rem;
    border: 1px solid;
    border-radius: 8px;
  }

  @media (hover: hover) {
    ul li a:hover {
      color: ${(props) => props.theme.accentColor};
    }
  }
  ${(props) =>
    props.showNav &&
    css`
      display: block;
    `}
`;

export default SubMenu;
