import React from "react";
import {
  AiFillHome,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineMail,
} from "react-icons/ai";
import {
  FaGoodreadsG,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaRss,
} from "react-icons/fa";
import HamburgerIcon from "./Hamburger";

export const ICONS = {
  HAMBURGER: "hamburger",
  HOME: "home",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  GOODREADS: "goodreads",
  EMAIL: "email",
  RIGHT: "right",
  LEFT: "left",
  RSS: "rss",
};

function Icon({ symbol, size = 20 }) {
  switch (symbol) {
    case ICONS.HAMBURGER:
      return <HamburgerIcon />;
    case ICONS.HOME:
      return <AiFillHome size={size} />;
    case ICONS.TWITTER:
      return <AiOutlineTwitter size={size} />;
    case ICONS.INSTAGRAM:
      return <AiOutlineInstagram size={size} />;
    case ICONS.GOODREADS:
      return <FaGoodreadsG size={size} />;
    case ICONS.EMAIL:
      return <AiOutlineMail size={size} />;
    case ICONS.LEFT:
      return <FaLongArrowAltLeft size={size} />;
    case ICONS.RIGHT:
      return <FaLongArrowAltRight size={size} />;
    case ICONS.RSS:
      return <FaRss size={size} />;
    default:
      return (
        <span>
          Unknown icon:
          {symbol}
        </span>
      );
  }
}

export default Icon;
