import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { media, font } from "../../styled";
import slideShowLinkHelper from "./helpers/slideShowLinkHelper";

const SlideShowCaption = ({ slide }) => {
  return (
    slide.caption && (
      <Link to={slideShowLinkHelper(slide)}>
        <SlideCaption>{slide.caption}</SlideCaption>
      </Link>
    )
  );
};

const SlideCaption = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${(props) =>
    props.captionColour ? props.captionColour : "white"} !important;

  text-align: center;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  width: 50%;
  ${media.maxSmall} {
    font-size: ${font.baseSize};
    line-height: ${font.baseLineHeight};
  }
`;

export default SlideShowCaption;
