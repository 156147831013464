import React from "react";
import styled from "styled-components";

import Figure from "../Figure";
import { resolveUrl } from "../../../helpers";

const serializers = {
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    ingredientReference: ({ node }) => (
      <span>
        {node.amount && node.amount.concat(node.measure ? "" : " ")}
        {node.measure && node.measure.type.concat(" ")}
        {node.ingredient.name.concat(" ")}
      </span>
    ),
    mainImage: Figure,
  },
  marks: {
    internalLink: ({ mark, children }) => {
      let href = "/";
      if (mark && mark.reference) {
        const { slug, publishedAt, _type: type } = mark.reference;

        if (type && publishedAt && slug) {
          href = resolveUrl(type, slug);
        }
      }
      return <StyledLink href={href}>{children}</StyledLink>;
    },
    link: ({ mark, children }) => {
      return <StyledLink href={mark.href}>{children}</StyledLink>;
    },

    "0574167bff5a": ({ children }) => {
      return <span>{children}</span>;
    },
  },
};

const StyledLink = styled.a`
  color: ${(props) => props.theme.accentColor};
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export default serializers;
