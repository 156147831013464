import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colour, font, media } from "../../styled";
import Icon from "../Icon";
import routes from "../../../constants/routes";
import { rssFeedUrl } from "../../../constants";

const Footer = ({ social }) => (
  <FooterWrapper>
    <SiteInfo>
      <SiteMap role="menu">
        <li role="menuitem">
          <Link to={routes.home} aria-label="Home">
            Home
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.books} aria-label="books">
            Books
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.authors} aria-label="authors">
            Authors
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.tags} aria-label="all about books">
            All About Books
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.readingChallenges} aria-label="reading challenges">
            Reading Challenges
          </Link>
        </li>
        <li role="menuitem">
          <Link to={routes.about} aria-label="about">
            About
          </Link>
        </li>
      </SiteMap>
    </SiteInfo>
    <SiteInfo>
      <SocialLinks role="menu">
        <SocialLinkItem role="menuitem">
          <a href={social.instagram} aria-label="instagram">
            <Icon symbol="instagram" size={22} />
          </a>
        </SocialLinkItem>
        <SocialLinkItem role="menuitem">
          <a href={social.twitter} aria-label="twitter">
            <Icon symbol="twitter" size={22} />
          </a>
        </SocialLinkItem>
        <SocialLinkItem role="menuitem">
          <a href={`mailto:${social.email}`} aria-label="email">
            <Icon symbol="email" size={22} />
          </a>
        </SocialLinkItem>
        <SocialLinkItem role="menuitem">
          <a href={rssFeedUrl} aria-label="rss">
            <Icon symbol="rss" size={22} />
          </a>
        </SocialLinkItem>
      </SocialLinks>
      <Credits>
        <span>©</span>
        <span>
          {new Date().getFullYear()}
          <span>
            {`, Built by `}
            <a href="https://www.brannen.dev">Brannen Dev</a>
          </span>
        </span>
      </Credits>
    </SiteInfo>
  </FooterWrapper>
);

const FooterWrapper = styled.footer`
  border-top: 1px solid ${colour.veryLightGray};
  box-sizing: border-box;
  padding: 2em 2em 2em;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;

  ${media.maxSmall} {
    grid-template-columns: 1fr;
  }
`;

const SiteInfo = styled.div`
  text-align: center;
  font-size: ${font.smallSize};
  line-height: ${font.smallLineHeight};

  a {
    color: inherit;
  }
`;

const SiteMap = styled.ul`
  list-style: none;
  text-align: left;
  li {
    height: 48px;
  }

  li a {
    text-decoration: none;
    height: 48px;
  }
`;

const SocialLinks = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${media.minSmall} {
    padding-left: 25%;
  }
`;

const SocialLinkItem = styled.li`
  margin-right: 1em;
  ${media.minSmall} {
    margin: 1em;
  }
`;

const Credits = styled.div`
  bottom: 0;
  padding: 2rem;
  position: relative;
`;

export default Footer;
