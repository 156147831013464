import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { font } from "./typography";

const GlobalStyle = createGlobalStyle`
  html {
    font-family: sans-serif, -apple-system, BlinkMacSystemFont;
    font-size: ${font.baseSize};
    line-height: ${font.baseLineHeight};
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: ${(props) => props.theme.backgroundColor};;
    color:${(props) => props.theme.primaryColor};
    margin: 0;
  }

  html,
  body,
  body > div,
  body > div > div {
    height: 100%;
  }
`;

export default ({ children, colours }) => (
  <ThemeProvider
    theme={{
      primaryColor: colours.primary,
      accentColor: colours.accent,
      backgroundColor: colours.background,
    }}
  >
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
